<template>
  <div class="deal-view">
    <b-row>
      <b-col>
        <deal-header v-if="deal.id" :deal="deal" @dealUpdated="dealUpdated" />
      </b-col>
    </b-row>
    <b-tabs v-if="deal.id" class="mt-1" content-class="pt-1" fill small>
      <b-tab title="Баланс">
        <deals-balance></deals-balance>
      </b-tab>
      <b-tab title="Транзакции">
        <deals-transaction></deals-transaction>
      </b-tab>
      <b-tab title="Оплата">
        <deal-payment></deal-payment>
      </b-tab>
      <b-tab title="Выставленные платежи">
        <deal-invoice></deal-invoice>
      </b-tab>
      <b-tab title="Комментарии">
        <deal-comments />
      </b-tab>
      <b-tab title="Штрафы">
        <deal-adjustments />
      </b-tab>
      <b-tab title="Паузы">
        <deal-pauses />
      </b-tab>
      <b-tab title="Управление">
        <deal-control
          v-if="deal.id"
          v-model="deal"
          @getDeal="getDeal"
          @updateDeal="updateDeal"
        ></deal-control>
      </b-tab>
      <b-tab title="Уведомления">
        <notifications-table :notifications="notifications" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import DealsBalance from '@/views/deals/components/DealBalance'
import DealsTransaction from '@/views/deals/components/DealTransaction'
import DealInvoice from '@/views/deals/components/DealInvoice'
import DealPayment from '@/views/deals/components/DealPayment'
import DealControl from '@/views/deals/components/DealControl'
import DealHeader from '@/views/deals/components/DealHeader'
import DealComments from '@/views/deals/components/DealComments'
import DealAdjustments from '@/views/deals/components/DealAdjustments.vue'
import DealPauses from '@/views/deals/components/DealPauses.vue'
import NotificationsTable from '@/views/notifications/components/NotificationsTable'

export default {
  name: 'DealView',
  components: {
    NotificationsTable,
    DealAdjustments,
    DealComments,
    DealHeader,
    DealControl,
    DealPayment,
    DealInvoice,
    DealsTransaction,
    DealsBalance,
    DealPauses,
  },
  data() {
    return {
      id: this.$route.params.id,
      deal: {},
      notifications: [],
    }
  },
  created() {
    this.getDeal()
    this.getNotifications()
  },
  methods: {
    async getDeal() {
      const res = await this.$http.get(`admin/deals/${this.id}`)
      this.deal = res.data
    },
    async updateDeal() {
      try {
        const res = await this.$http.patch(`admin/deals/${this.id}/`, this.deal)
        this.deal = res.data
        this.$toast.success('Обновлено')
      } catch (e) {
        this.$toast.danger('Ошибка')
      }
    },
    dealUpdated(deal) {
      this.deal = deal
    },
    async getNotifications() {
      const res = await this.$http.get(`admin/notifications/`, {
        params: { deal: this.id },
      })
      this.notifications = res.data.results
    },
  },
}
</script>
