<template>
  <div class="">
    <DealFine :fine-type="0" title="Штрафы по договору микрозайма" />
    <DealFine :fine-type="1" title="Штрафы по договору залога" />
    <DealFine :fine-type="2" title="Госпошлина" />
  </div>
</template>
<script>
import DealFine from '@/views/deals/components/DealFine'

export default {
  components: { DealFine },
  props: {
    fineType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      today: new Date().toISOString().substring(0, 10),

      show: false,

      fine: {
        fine_type: this.fineType,
        deal: this.$route.params.id,
        date: new Date().toISOString().substring(0, 10),
        amount: 0,
        comment: '',
      },
      fines: [],

      fineFields: [
        { key: 'id', label: 'ID' },
        { key: 'date', label: 'Дата' },
        { key: 'amount', label: 'Сумма' },
        { key: 'comment', label: 'Комментарий' },
        { key: 'actions', label: 'Действия' },
      ],

      writeoff: {},
      writeoffs: [],
    }
  },
  created() {
    this.getFines()
  },
  methods: {
    async getFines() {
      const res = await this.$http.get(
        `admin/deal-fines/?deal=${this.$route.params.id}&fine_type=${this.fineType}`
      )
      this.fines = res.data.results
    },

    async addFine() {
      this.show = true
      await this.$http
        .post(`admin/deal-fines/`, this.fine)
        .then(() => {
          this.getFines()
          this.fine = {}
          this.show = false
          this.$router.go(this.$router.currentRoute)
        })
        .catch((err) => {
          this.$toast.error(
            `Сумма штрафа превышает сумму долга * 0.5 \n ОД: ${err.response.data.principal} \n Штраф: ${err.response.data.fine}`
          )
          this.show = false
        })
    },

    async deleteFine(id) {
      console.log(id)
      this.$bvModal
        .msgBoxConfirm('Вы уверены, что хотите удалить штраф?', {
          title: 'Подтвердите',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Да',
          cancelTitle: 'Нет',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await this.$http.delete(`admin/deal-fines/${id}`)
            await this.getFines()
            this.$router.go(this.$router.currentRoute)
          }
        })
    },
  },
}
</script>
