<template>
  <div class="deal-invoice">
    <b-row>
      <b-col>
        <b-card>
          <b-row class="mt-0">
            <div class="ml-2">
              <label for="datepicker">
                Посмотреть выставленные платежи на дату
              </label>
              <b-form inline>
                <b-button variant="outline-primary" @click="prevHistoryDay()"
                  >Назад
                </b-button>
                <b-form-datepicker
                  id="datepicker"
                  v-model="historyDate"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :reset-button="true"
                  class="ml-1 mr-1"
                  locale="ru"
                ></b-form-datepicker>

                <b-button variant="outline-primary" @click="nextHistoryDay()"
                  >Вперед
                </b-button>
                <b-button
                  class="ml-1"
                  variant="outline-primary"
                  @click="clearDate()"
                  >Сбросить дату
                </b-button>
              </b-form>

              <p v-if="historyDate" style="color: red">
                Вы смотрите платежи на дату: {{ historyDate }}
              </p>
            </div>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <b-table
                v-if="rowClass"
                :fields="fields"
                :items="invoices"
                :tbody-tr-class="rowClass"
                empty-text="Нет данных"
                responsive
                show-empty
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import dayjs from 'dayjs'

let customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

export default {
  name: 'DealInvoice',
  data() {
    return {
      historyDate: '',
      invoices: [],
      fields: [
        {
          key: 'due_date',
          label: 'Дата оплаты',
        },
        {
          key: 'balance_amount',
          label: 'ОД',
        },
        {
          key: 'perc_value',
          label: 'Проценты',
        },
        {
          key: 'days_delayed',
          label: `Дней просрочки`,
        },
        {
          key: 'fine_value',
          label: 'Пеня',
        },

        {
          key: 'late_payment_fine',
          label: 'Штраф',
        },

        {
          key: 'duty_value',
          label: 'Гос пошлина',
        },
      ],
    }
  },
  watch: {
    historyDate() {
      if (this.historyDate !== '') {
        this.getHistoryInvoices(this.$route.params.id, this.historyDate)
      } else {
        this.getInvoices(this.$route.params.id)
      }
    },
  },
  created() {
    this.getInvoices(this.$route.params.id)
  },
  methods: {
    clearDate() {
      this.historyDate = ''
    },
    prevHistoryDay() {
      let prevDate = dayjs(this.historyDate).subtract(1, 'day')
      this.historyDate = prevDate.format('YYYY-MM-DD')
    },
    nextHistoryDay() {
      let nextDate = dayjs(this.historyDate).add(1, 'day')
      this.historyDate = nextDate.format('YYYY-MM-DD')
    },
    async getInvoices(dealId) {
      const res = await this.$http.get(`admin/deal/${dealId}/invoice/`)
      this.invoices = res.data.results
    },
    async getHistoryInvoices(dealId, date) {
      const res = await this.$http.get(
        `admin/deal/${dealId}/invoice/history?date=${date}`
      )
      this.invoices = res.data
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      let duedt = dayjs(item.due_date, 'DD.MM.YYYY')
      if (duedt > dayjs().endOf('month')) return 'table-active'
    },
  },
}
</script>
