<template>
  <div class="">
    <b-row>
      <b-col cols="12" lg="8" md="7">
        <b-card :title="title" border-variant="light">
          <b-overlay :show="show" no-wrap></b-overlay>
          <b-table
            :fields="fineFields"
            :items="fines"
            bordered
            empty-text="Нет данных"
            responsive
            show-empty
          >
            <template #cell(actions)="row">
              <b-button class="mr-2" size="sm" @click="deleteFine(row.item.id)">
                Удалить
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col cols="12" lg="4" md="5">
        <b-card>
          <b-overlay :show="show" no-wrap></b-overlay>
          <b-form-group label="Комментарий">
            <b-form-textarea v-model="fine.comment" />
          </b-form-group>
          <b-form-group label="Сумма" label-for="v-amount">
            <b-form-input
              id="v-value"
              v-model="fine.amount"
              min="0"
              placeholder="Сумма"
              step="0.01"
              type="number"
            />
          </b-form-group>
          <b-form-group label="Дата штрафа">
            <b-form-datepicker
              id="datepicker-placeholder"
              v-model="fine.date"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :initial-date="today"
              :max="today"
              locale="ru"
            />
          </b-form-group>
          <b-button class="mt-1" @click="addFine"> Добавить штраф</b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: {
    fineType: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      today: new Date().toISOString().substring(0, 10),

      show: false,

      fine: {
        fine_type: this.fineType,
        deal: this.$route.params.id,
        date: new Date().toISOString().substring(0, 10),
        amount: 0,
        comment: '',
      },
      fines: [],

      fineFields: [
        { key: 'id', label: 'ID' },
        { key: 'date', label: 'Дата' },
        { key: 'amount', label: 'Сумма' },
        { key: 'comment', label: 'Комментарий' },
        { key: 'actions', label: 'Действия' },
      ],

      writeoff: {},
      writeoffs: [],
    }
  },
  created() {
    this.getFines()
  },
  methods: {
    async getFines() {
      const res = await this.$http.get(
        `admin/deal-fines/?deal=${this.$route.params.id}&fine_type=${this.fineType}`
      )
      this.fines = res.data.results
    },

    async addFine() {
      this.show = true
      await this.$http
        .post(`admin/deal-fines/`, this.fine)
        .then(() => {
          this.getFines()
          this.fine = {}
          this.show = false
          this.$router.go(this.$router.currentRoute)
        })
        .catch((err) => {
          this.$toast.error(
            `Сумма штрафа превышает сумму долга * 0.5 \n ОД: ${err.response.data.principal} \n Штраф: ${err.response.data.fine}`
          )
          this.show = false
        })
    },

    async deleteFine(id) {
      console.log(id)
      this.$bvModal
        .msgBoxConfirm('Вы уверены, что хотите удалить штраф?', {
          title: 'Подтвердите',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Да',
          cancelTitle: 'Нет',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await this.$http.delete(`admin/deal-fines/${id}`)
            await this.getFines()
            this.$router.go(this.$router.currentRoute)
          }
        })
    },
  },
}
</script>
