<template>
  <div>
    <b-row>
      <b-col class="text-right">
        <b-button
          class="mr-1 primary"
          variant="primary"
          :disabled="loading"
          @click="recalculateDeal"
          >Пересчитать
          <b-spinner v-if="loading" small></b-spinner>
        </b-button>
        <b-dropdown
          variant="outline-primary"
          :text="deal.status_display"
          split
          dropleft
        >
          <b-dropdown-item
            @click="loadExcel('doc/2', 'Договор_Мозжухин', 'docx')"
          >
            Скачать договор (Мозжухин)
          </b-dropdown-item>
          <b-dropdown-item
            @click="loadExcel('doc/1', 'Договор_Гайваронская', 'docx')"
          >
            Скачать договор (Гайваронская)
          </b-dropdown-item>
          <b-dropdown-item @click="loadExcel('claim', 'Претензия', 'docx')">
            Скачать претензию
          </b-dropdown-item>
          <b-dropdown-item
            @click="loadExcel('closed', 'Справка_погашение', 'docx')"
          >
            Скачать справку о погашении
          </b-dropdown-item>
          <b-dropdown-item
            @click="loadExcel('excel', 'Разбивка_платежей', 'xlsx')"
          >
            Скачать разбивку платежей
          </b-dropdown-item>
          <b-dropdown-item v-if="deal.status === 0" @click="changeStatus(1)">
            Подписать
          </b-dropdown-item>
          <b-dropdown-item v-if="deal.status === 0" @click="changeStatus(2)">
            Отклонить
          </b-dropdown-item>
          <b-dropdown-item v-if="deal.status === 1" @click="changeStatus(3)">
            Завершить
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <b-card class="mt-2">
      <b-row>
        <b-col md="2">
          <h5>
            <router-link
              :to="{ name: 'client-view', params: { id: deal.client } }"
            >
              {{ deal.initials }}
            </router-link>
          </h5>
          <h6 class="font-weight-light">{{ deal.document_number }}</h6>
          <span class="card-text">{{ deal.phone }}</span>
        </b-col>
        <b-col class="d-flex align-items-center flex-wrap">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ deal.value || 0 }} BYN</h5>
              <small>Сумма займа</small>
            </div>
          </div>
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-warning" rounded>
              <feather-icon icon="PercentIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ deal.rate * 100 || 0 }}%</h5>
              <small>Ставка</small>
            </div>
          </div>
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-info" rounded>
              <feather-icon icon="ClockIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ deal.period || 0 }} мес</h5>
              <small>Срок</small>
            </div>
          </div>
          <hr />
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ deal.current_balance || 0 }} BYN</h5>
              <small>Остаток ОД</small>
            </div>
          </div>
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-warning" rounded>
              <feather-icon icon="PercentIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ deal.current_percents || 0 }} BYN</h5>
              <small>Начисленные %</small>
            </div>
          </div>
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-danger" rounded>
              <feather-icon icon="AlertTriangleIcon " size="18" />
            </b-avatar>
            <div class="ml-1">
              <small>{{ deal.current_fine }} - пеня</small><br />
              <small>{{ deal.current_late_fine }} - штраф</small><br />
              <small>{{ deal.current_duty }} - госпошлина</small><br />
              <small>{{ deal.full_amount }} - полное погашение</small><br />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'DealHeader',
  props: ['deal'],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    apiUrl() {
      return this.$http.defaults.baseURL
    },
  },
  methods: {
    async changeStatus(status) {
      const res = await this.$http.patch(
        `${this.apiUrl}admin/deals/${this.deal.id}/`,
        {
          status: status,
        }
      )
      this.$emit('dealUpdated', res.data)
    },
    async recalculateDeal() {
      this.loading = true
      await this.$http
        .get(`${this.apiUrl}admin/deal/${this.deal.id}/recalculate/`)
        .then(() => {
          this.loading = false
          this.$router.go(this.$router.currentRoute)
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
    async loadExcel(params, title, format) {
      const res = await this.$http.get(
        `admin/deal/${this.deal.id}/${params}/`,
        {
          responseType: 'blob',
        }
      )
      const url = URL.createObjectURL(
        new Blob([res.data], {
          type: `application/vnd.openxmlformats-officedocument.${
            format === 'docx'
              ? 'wordprocessingml.document'
              : 'spreadsheetml.sheet'
          }`,
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${this.deal.id}_${title}.${format}`)
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    },
  },
}
</script>
