<template>
  <div class="d-flex align-items-center mr-2">
    <b-avatar variant="light-success" rounded>
      <feather-icon icon="DollarSignIcon" size="18" />
    </b-avatar>
    <div class="ml-1">
      <h5 class="mb-0">{{ amount || 0 }} BYN</h5>
      <small>{{ title }}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MediaCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    amount: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {}
  },

  methods: {},
}
</script>
