<template>
  <div class="deal-invoice">
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col class="text-right"></b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <b-card border-variant="light" title="Распределение">
                <b-col class="d-flex align-items-center flex-wrap">
                  <media-card
                    :amount="distribution.total_paid"
                    title="Сумма всех платежей"
                  >
                  </media-card>
                  <media-card
                    :amount="distribution.transaction_paid_balance"
                    title="Основной долг"
                  >
                  </media-card>
                  <media-card
                    :amount="distribution.transaction_paid_percents"
                    title="Проценты"
                  >
                  </media-card>
                  <media-card
                    :amount="distribution.transaction_paid_fine"
                    title="Пеня"
                  >
                  </media-card>
                  <media-card
                    :amount="distribution.transaction_paid_late_fine"
                    title="Штрафы"
                  >
                  </media-card>
                </b-col>

                <b-col class="align-items-end">
                  <b-button
                    v-b-toggle.sidebar-backdrop
                    class="mt-2"
                    variant="primary"
                  >
                    Добавить прямой платеж
                  </b-button>
                  <b-sidebar
                    id="sidebar-backdrop"
                    ref="sidebar"
                    backdrop
                    backdrop-variant="dark"
                    right
                    shadow
                    title="Добавить платеж"
                  >
                    <deal-add-payment></deal-add-payment>
                  </b-sidebar>
                </b-col>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <b-table
                :fields="fields"
                :items="payments"
                bordered
                empty-text="Нет данных"
                hover
                responsive
                show-empty
              >
                <template #cell(distribution_details)="row">
                  <b-button class="mr-2" size="sm" @click="row.toggleDetails">
                    {{ row.detailsShowing ? 'Спрятать' : 'Показать' }} разбивку
                  </b-button>
                  <b-button
                    v-b-modal.add-handle-distribution
                    size="sm"
                    @click="paymentId = row.item.id"
                  >
                    Ручное распределение
                  </b-button>
                </template>

                <template #row-details="row">
                  <b-table-lite
                    :fields="subFields"
                    :items="row.item.transactions"
                  >
                  </b-table-lite>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <add-handle-distribution :id="paymentId"></add-handle-distribution>
  </div>
</template>

<script>
import DealAddPayment from '@/views/deals/components/DealAddPayment.vue'
import MediaCard from '@/views/helpers/MediaCard.vue'
import AddHandleDistribution from '@/views/deals/components/AddHandleDistribution.vue'

export default {
  name: 'DealPayment',
  components: { MediaCard, DealAddPayment, AddHandleDistribution },
  data() {
    return {
      payments: [],
      distribution: {},
      paymentId: 0,
      fields: [
        {
          key: 'date',
          label: 'Дата оплаты',
        },
        {
          key: 'value',
          label: 'Сумма',
        },
        {
          key: 'source_display',
          label: 'Источник',
        },
        {
          key: 'comment',
          label: 'Комментарий',
        },
        {
          key: 'distribution_details',
          label: 'Разбивка',
        },
      ],
      subFields: [
        {
          key: 'balance_value',
          label: 'Осн. долг',
        },
        {
          key: 'perc_value',
          label: 'Проценты',
        },
        {
          key: 'fine_value',
          label: 'Пеня',
        },
        {
          key: 'late_payment_fine',
          label: 'Штраф',
        },
        {
          key: 'duty_value',
          label: 'Гос. пошлина',
        },
        {
          key: 'comment',
          label: 'Комментарий',
        },
      ],
    }
  },
  created() {
    this.getPayments(this.$route.params.id)
    this.getDistribution(this.$route.params.id)
  },
  methods: {
    async getPayments(dealId) {
      const res = await this.$http.get(`admin/deal/${dealId}/payment/`)
      this.payments = res.data.results
    },

    async getDistribution(dealId) {
      const res = await this.$http.get(`admin/deal/${dealId}/distribution/`)
      this.distribution = res.data
    },
  },
}
</script>
