<template>
  <div class="">
    <!--    FINE PAUSE  -->
    <b-row>
      <b-col md="7" lg="8" cols="12">
        <b-card title="Пауза пени" border-variant="light">
          <b-overlay :show="show" no-wrap></b-overlay>
          <b-table
            :items="finePauses"
            :fields="finePausesFields"
            show-empty
            bordered
            empty-text="Нет данных"
            responsive
          >
            <template #cell(actions)="row">
              <b-button
                size="sm"
                class="mr-2"
                @click="deleteFinePause(row.item.id)"
              >
                Удалить
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col md="5" lg="4" cols="12">
        <b-card>
          <b-overlay :show="show" no-wrap></b-overlay>
          <b-form-group label="Комментарий">
            <b-form-textarea v-model="finePause.comment" />
          </b-form-group>
          <b-form-group label="Дата начала">
            <b-form-datepicker
              id="datepicker-placeholder"
              v-model="finePause.start_date"
              locale="ru"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :initial-date="today"
            />
          </b-form-group>
          <b-form-group label="Дата окончания">
            <b-form-datepicker
              id="datepicker-placeholder"
              v-model="finePause.end_date"
              locale="ru"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :initial-date="today"
            />
          </b-form-group>
          <b-button class="mt-1" @click="addFinePause">
            Добавить паузу по пене</b-button
          >
        </b-card>
      </b-col>
    </b-row>

    <!--PERCENT PAUSE-->
    <b-row>
      <b-col md="7" lg="8" cols="12">
        <b-card title="Пауза процентов" border-variant="light">
          <b-overlay :show="show" no-wrap></b-overlay>
          <b-table
            :items="percentPauses"
            :fields="percentPausesFields"
            show-empty
            bordered
            empty-text="Нет данных"
            responsive
          >
            <template #cell(actions)="row">
              <b-button
                size="sm"
                class="mr-2"
                @click="deletePercentPause(row.item.id)"
              >
                Удалить
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col md="5" lg="4" cols="12">
        <b-card>
          <b-overlay :show="show" no-wrap></b-overlay>
          <b-form-group label="Комментарий">
            <b-form-textarea v-model="percentPause.comment" />
          </b-form-group>
          <b-form-group label="Дата начала">
            <b-form-datepicker
              id="datepicker-placeholder"
              v-model="percentPause.start_date"
              locale="ru"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :initial-date="today"
            />
          </b-form-group>
          <b-form-group label="Дата окончания">
            <b-form-datepicker
              id="datepicker-placeholder"
              v-model="percentPause.end_date"
              locale="ru"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :initial-date="today"
            />
          </b-form-group>
          <b-button class="mt-1" @click="addPercentPause">
            Добавить паузу по процентам</b-button
          >
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      today: new Date().toISOString().substring(0, 10),

      show: false,

      finePause: {
        deal: this.$route.params.id,
        start_date: new Date().toISOString().substring(0, 10),
        end_date: new Date().toISOString().substring(0, 10),
        comment: '',
        pause_type: 0,
      },
      finePauses: [],

      finePausesFields: [
        { key: 'id', label: 'ID' },
        { key: 'start_date', label: 'Дата начала' },
        { key: 'end_date', label: 'Дата окончания' },
        { key: 'comment', label: 'Комментарий' },
        { key: 'actions', label: 'Действия' },
      ],

      percentPause: {
        deal: this.$route.params.id,
        start_date: new Date().toISOString().substring(0, 10),
        end_date: new Date().toISOString().substring(0, 10),
        comment: '',
        pause_type: 1,
      },
      percentPauses: [],

      percentPausesFields: [
        { key: 'id', label: 'ID' },
        { key: 'start_date', label: 'Дата начала' },
        { key: 'end_date', label: 'Дата окончания' },
        { key: 'comment', label: 'Комментарий' },
        { key: 'actions', label: 'Действия' },
      ],

      writeoff: {},
      writeoffs: [],
    }
  },
  created() {
    this.getFinePauses()
    this.getPercentPauses()
  },
  methods: {
    async getFinePauses() {
      const res = await this.$http.get(
        `admin/deal-pauses/?deal=${this.$route.params.id}&pause_type=0`
      )
      this.finePauses = res.data.results
    },
    async getPercentPauses() {
      const res = await this.$http.get(
        `admin/deal-pauses/?deal=${this.$route.params.id}&pause_type=1`
      )
      this.percentPauses = res.data.results
    },
    async addFinePause() {
      this.show = true
      await this.$http.post('admin/deal-pauses/', this.finePause)
      await this.getFinePauses()
      this.finePause = {}
      this.show = false
      this.$router.go(this.$router.currentRoute)
    },
    async addPercentPause() {
      this.show = true
      await this.$http.post('admin/deal-pauses/', this.percentPause)
      await this.getPercentPauses()
      this.percentPause = {}
      this.show = false
      this.$router.go(this.$router.currentRoute)
    },
    async deleteFinePause(id) {
      console.log(id)
      this.$bvModal
        .msgBoxConfirm('Вы уверены, что хотите удалить паузу по штрафу?', {
          title: 'Подтвердите',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Да',
          cancelTitle: 'Нет',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await this.$http.delete(`admin/deal-pauses/${id}/`)
            await this.getFinePauses()
            this.$router.go(this.$router.currentRoute)
          }
        })
    },
    async deletePercentPause(id) {
      console.log(id)
      this.$bvModal
        .msgBoxConfirm('Вы уверены, что хотите удалить паузу по процентам?', {
          title: 'Подтвердите',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Да',
          cancelTitle: 'Нет',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await this.$http.delete(`admin/deal-pauses/${id}/`)
            await this.getPercentPauses()
            this.$router.go(this.$router.currentRoute)
          }
        })
    },
  },
}
</script>
