<template>
  <div class="deal-balance">
    <b-row>
      <b-col>
        <b-card>
          <b-row class="mt-4">
            <b-col>
              <b-table
                :items="balances"
                :fields="fields"
                show-empty
                empty-text="Нет данных"
                responsive
              >
                <template #cell(balance_value)="value">
                  <span v-if="value.value < 0" class="text-danger">{{
                    value.value
                  }}</span>
                  <span v-else>{{ value.value }}</span>
                </template>
                <template #cell(perc_value)="value">
                  <span v-if="value.value < 0" class="text-danger">{{
                    value.value
                  }}</span>
                  <span v-else>{{ value.value }}</span>
                </template>
                <template #cell(fine_value)="value">
                  <span v-if="value.value < 0" class="text-danger">{{
                    value.value
                  }}</span>
                  <span v-else>{{ value.value }}</span>
                </template>
                <template #cell(late_payment_fine)="value">
                  <span v-if="value.value < 0" class="text-danger">{{
                    value.value
                  }}</span>
                  <span v-else>{{ value.value }}</span>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'DealsBalance',
  data() {
    return {
      balances: [],
      fields: [
        {
          key: 'date',
          label: 'На дату',
        },
        {
          key: 'balance_value',
          label: 'Основной Долг',
        },
        {
          key: 'perc_value',
          label: 'Проценты',
        },
        {
          key: 'fine_value',
          label: 'Пеня',
        },
        {
          key: 'late_payment_fine',
          label: 'Штраф',
        },
        {
          key: 'duty_value',
          label: 'Гос пошлина',
        },
      ],
    }
  },
  created() {
    this.getBalances(this.$route.params.id)
  },
  methods: {
    async getBalances(dealId) {
      const res = await this.$http.get(`admin/deal/${dealId}/balance/`)
      this.balances = res.data.results
    },
  },
}
</script>
